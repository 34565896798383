import React, { useState } from 'react'
import Loader from '../components/Loader'
import Gradient from '../components/Gradient'
import Error from '../components/Error'
import Upload from '../components/upload/Upload'
import Connection from '../components/upload/Connection'
import { findSessionByCode, uploadPhoto } from '../api'


function UploadPage() {
  const [session, setSession] = useState(false)
  const connect = async code => {
    await setSession(undefined)
    setSession(await findSessionByCode(code))
  }
  const upload = async photo => {
    setSession(await uploadPhoto(session.code, [photo]))
  }
  if (session === undefined)
    return <Loader />
  if (session.error)
    return <Error>{session.error}</Error>
  if (session)
    return <Upload upload={upload} session={session} />
  return <Connection connect={connect} />
}

export default function UploadWithGradient() {
  return (
    <div>
      <style dangerouslySetInnerHTML={{ __html: `html { font-size: 130% }` }} />
      <Gradient />
      <UploadPage />
      <div className="fixed bottom-0 h-4 w-full bg-contain bg-no-repeat bg-center pointer-events-none" style={{ backgroundImage: 'url("https://storage.googleapis.com/phoprint/LOGO_CHEERZ_BLEU_1_1_1_592b3e8903/LOGO-CHEERZ-BLEU-(1)-(1)-(1).png_LOGO_CHEERZ_BLEU_1_1_1_592b3e8903.png")' }} />
    </div>
  )
}